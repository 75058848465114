<template>
    <div>
        <!-- CAROUSEL-->
    <!-- <b-row>
      <b-col cols="12" >
        <div>
          <b-carousel indicators :interval="3000" controls >
            <b-carousel-slide       
                v-if="resources!=null"         
                :img-src="resources.img_header_principal"
            />
          </b-carousel>
        </div>
      </b-col>
    </b-row>
     -->
        <br>

        <!-- <h1 class="titles">
          <span style="color: #d82125">M</span>
          <span style="color: #d02175">u</span>
          <span style="color: #f6c921">n</span>
          <span style="color: #d02175">i</span>
          <span style="color: #4d944a">c</span>
          <span style="color: #07aaba">i</span>
          <span style="color: #d82125">p</span>
          <span style="color: #d02175">i</span>
          <span style="color: #f6c921">o</span>
        </h1> -->
        
        <h1 class="titles">
          <span style="color: #8b8c8c">Venta de Artesanías</span>
        </h1>



        <br><br>

        <br><br>

                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/9%20artesanias/1.jpeg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/9%20artesanias/3.jpeg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/9%20artesanias/4.jpeg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/9%20artesanias/5.jpeg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/9%20artesanias/6.jpeg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/9%20artesanias/7.jpeg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/9%20artesanias/8.jpeg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/9%20artesanias/9.jpeg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/9%20artesanias/10.jpeg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/9%20artesanias/11.jpeg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/9%20artesanias/12.jpeg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/9%20artesanias/13.jpeg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/9%20artesanias/14.jpeg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/9%20artesanias/15.jpeg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/9%20artesanias/16.jpeg"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>

<br><br>
<b-row align-h="center">
              <b-col cols="11" md="8" lg="5" xl="5"  class="mb-5" style="height:380px;">
                  <div style="height:100%; width: 100%;">
                    <b-embed
                      class="c-shadow-sm"
                      style="width:100%; height:380px;"
                      type="iframe"
                      src="https://www.youtube.com/embed/TRdokTmw2Gc"
                    ></b-embed>
                  </div>
                </b-col>
                <b-col cols="11" md="8" lg="5" xl="5" class="mb-5" style="height:380px;">
                  <div style="height:100%; width: 100%;">
                    <b-embed
                      class="c-shadow-sm"
                      style="width:100%; height:380px;"
                      type="iframe"
                      src="https://www.youtube.com/embed/YUtDGCL-qsg"
                    ></b-embed>
                  </div>
                </b-col>
              </b-row>


                    <br>
    <b-row align-h="center">
      <b-col cols="11" md="9" lg="5"  xl="4" class="mt-4">
        <p>
          <b>
            Es una actividad que predomina en Teotihuacán, ya que en algunos establecimientos muestran las piedras en bruto, y como es su proceso de elaboración para así llevar a realizar diversas figuras que son significativas para la cultura teotihuacana.
          </b>
        </p>
      </b-col>
    </b-row>
 <br><br>

    <b-row  align-h="center" class="mt-4" v-for="(item, idx) in datos " :key="idx">
        <b-col cols="10" sm="8" md="5" lg="4">
          <b-row><h5><b>{{item.nombre}}</b></h5></b-row>
          <b-row><h6><b-icon icon="geo-fill"/> {{item.direccion}}</h6></b-row>
          <b-row><h6><b-icon icon="telephone-fill"/> {{item.telefono}}</h6></b-row>
          <b-row><h6><b-icon icon="grid"/> {{item.url}}</h6></b-row>
        </b-col>
    </b-row>

    </div>
</template>
<script>
  export default {
    components: {
    },
    mounted: function(){
      
    },
    computed: {
      
    },
    data() {
      return {
        resources:null,
        datos:[
          {img:'',nombre:'Adolfo Martínez Montes ',direccion:'Av. Del Panteon 8 Pablo San Francisco Mazapa, 55830, Teotihuacan, México.',telefono:'5585538861',url:'Lapidaria '},
{img:'',nombre:'Adriana Estela Zarraga Santiago',direccion:'C Veracruz 10 Pablo Santa Maria Coatlan, 55835, Teotihuacan, México.',telefono:'5620889866',url:'Lapidaria '},
{img:'',nombre:'Alberto Cortes Gutierrez',direccion:'Cda Cuatro Caminos S/N Pblo Santa Maria Maquixco 55800 Teotihuacan, México',telefono:'5522602832',url:'Hueso '},
{img:'',nombre:'Alejandra Herndez Mozqueda ',direccion:'Cda. Melchor Ocampo 2 Barrio Puxtla, 55800, Teotihiuacan, México.',telefono:'5522580424',url:'Alfareria '},
{img:'',nombre:'Alejandra Nayeli Rodriguez Velazquez ',direccion:'C. Del Puente S/N Pablo, San Francisco Mzapan, 55830, Teotihuacan, México.',telefono:'5528921742',url:'Lapidaria '},
{img:'',nombre:'Alejandro Alvarez Pineda ',direccion:'Ex Hacienda la Cadena Lt 6 Pblo Maquixco 55840 Teotihuacan, México',telefono:'5584915007',url:'Vidrio '},
{img:'',nombre:'Ambar Anahi Cortes Fernandez ',direccion:'C Insurgentes S/N Barr Puxtla 55800 Teotihuacan, México',telefono:'5531519966',url:'Cartoneria '},
{img:'',nombre:'America Laura Gleasson Rodriguez',direccion:'C la Presa 16 Barr Purificacion 55804 Teotihuacan, México',telefono:'5626571312',url:'Medicina Tradicional '},
{img:'',nombre:'Andrea Rosa Hernandez Garcia',direccion:'c la Presa 5 Barr de Purificacion 55810 Teotihuacan, México',telefono:'5531117499',url:'Agroindustria '},
{img:'',nombre:'Angel Odin Barrios Guerrero  ',direccion:'Calle Morelos San Sebastian Xolalpan ',telefono:'5567056763',url:'Alfareria '},
{img:'',nombre:'Angelica Baldominos Aguirre ',direccion:'Cda. Pemex 8 Barrio Purificación, 55804, Teotihuacan, México.',telefono:'',url:'Alfareria '},
{img:'',nombre:'Angelica Gomez Trejo ',direccion:'C. Veracruz 10 Pablo Santa María Coatlan, 55835, Teotihuacan, México. ',telefono:'5586499660',url:'Lapidaria '},
{img:'',nombre:'Angelica Ortega Juarez ',direccion:'Cda. Del Reloj 5 Pablo San Francisco Mazapa, 55830, Teotihuacan, México. ',telefono:'5512942875',url:'Lapidaria '},
{img:'',nombre:'Antonio Garcia Juarez ',direccion:'C. Miguel Allende 15 Pablo San Francisco Mazapa, 55820, Teotihuacan, México. ',telefono:'5568677671',url:'Lapidaria '},
{img:'',nombre:'Arturo Espinoza Ignacio ',direccion:'Cda. Justo Sierra 12 Pablo Santa Maria Coatlan, 55835, Teotihuacan, México.',telefono:'5538986296',url:'Lapidaria '},
{img:'',nombre:'Aureliano Vela Andreano ',direccion:'Av. Libertad S/N San Sebastian Xolalpan, 55824, Teotihuacan, México. ',telefono:'5941086191',url:'Alfareria '},
{img:'',nombre:'Avedalia Rodriguez Vega ',direccion:'C Campo Florido 15 8 Barrio de Purificación, Teotihuacan, México.',telefono:'5577090679',url:'Agroindustria '},
{img:'',nombre:'Benito Ramirez Gomez ',direccion:'Cda. Morelos Pablo, San Francisco Mazapa, 55830, Teotihuacan, México. ',telefono:'5523310469',url:'Lapidaria '},
{img:'',nombre:'Brenda Espinoza Briones ',direccion:'C. Campo Florido 29 Barrio Purificación 55810, Teotihuacan, México.',telefono:'5565010443',url:'Madera '},
{img:'',nombre:'Brenda Saldaña Alvarez ',direccion:'Cda. De la Cruz S/N Pablo San Francisco Mazapa, 55820, Teotihuacan, México.',telefono:'5549213671',url:'Lapidaria '},
{img:'',nombre:'Carlos Antonio Santiago Cisneros ',direccion:'Cda Veracruz 10 Pblo Santa Maria coatlan 55835 Teotihuacan, México',telefono:'5559430045',url:'Lapidaria '},
{img:'',nombre:'Carlota Castillo Trejo ',direccion:'Cam Viejo a Metepec S/N Pablo San Sebastian Xolalpa ,55800, Teotihuacan, Mexico.',telefono:'5547022524',url:'Lapidaria '},
{img:'',nombre:'Catalina Arena Macias ',direccion:'Cda. De los Deportes 1 Pablo San Lorenzo Tlamininolpan, Teotihuacan, México.',telefono:'5516464856',url:'Alfareria '},
{img:'',nombre:'Clara Angelica Rodriguez Gonzales ',direccion:'C Fresno 7 Col Centro 55800 Teotihuacan, México',telefono:'5515914909',url:'Cartoneria '},
{img:'',nombre:'Claudia Martínez Campos ',direccion:'Av. Del Trabajo 13 Santa Maria Coatlan, 55835, Teotihuacan, México.',telefono:'5615271662',url:'Lapidaria '},
{img:'',nombre:'Consuelo Jimenez Monterrubio',direccion:'C Contitución 24 Pablo San Sebastian Xolalpa, 55824, Teotihuacan, México.',telefono:'5551566768',url:'Alfareria '},
{img:'',nombre:'Cristina Elsa Oliva Olvera ',direccion:'Carr México Tulancingo Km 25 Pablo San Sebastian Xolalpan, 55840, Teotihuacan, México.',telefono:'5617661972',url:'Lapidaria '},
{img:'',nombre:'Cuitlahuac Efrain Cortes Soto',direccion:'Cda Potrero SN Loc San Lorenzo Tlalmimilolpan 55800 Teotihuacan México',telefono:'7751018833',url:'Agroindustria '},
{img:'',nombre:'Daniel Alejandro Ortiz Pacheco ',direccion:'Cda. Justo Sierra 8 Barrio Evangelista, 55816, Teotihuacan, México. ',telefono:'5560612246',url:'Lapidaria '},
{img:'',nombre:'Daniel Juarez Meza ',direccion:'Cda. Del Reloj 5 Pablo San Francisco Mazapa, 55830, Teotihuacan, México.',telefono:'5532454237',url:'Lapidaria '},
{img:'',nombre:'Edna Citlalli Hernandez Moreno ',direccion:'C. Minerva 11 Pblo San Francisco Mazapa 55830 Teotihuacan, México',telefono:'5547977438',url:'Agroindustria '},
{img:'',nombre:'Eloy Campos Campos ',direccion:'C. Minerva 10 Pablo San Francisco Mazapa, 55830, Teotihucan, México.',telefono:'5514777015',url:'Lapidaria '},
{img:'',nombre:'Emelia Karina Sandoval Martínez ',direccion:'C. Jesus María 3 Pablo Santa María Coatlan, 55835, Teotihuacan, México. ',telefono:'5591996054',url:'Lapidaria '},
{img:'',nombre:'Enrique Barrios Sanchez ',direccion:'C Morelos 20 Pablo San Sebastian Xolalpan, 55840, Teotihuacan, México.',telefono:'5514634763',url:'Alfareria '},
{img:'',nombre:'Esperanza Isabel Cervantes Aguilar ',direccion:'Av. Del Trabajo 6 Santa Maria Coatlan, 55835, Teotihuacan, México.',telefono:'5564144382',url:'Lapidaria '},
{img:'',nombre:'Esperanza Morales Morales ',direccion:'C. Ejidos de Tlajinga S/N Ej Purificación 55812, Teotihuacan, México.',telefono:'5547739025',url:'Alfareria '},
{img:'',nombre:'Fidel Juerez Disciplina ',direccion:'Cda. Viejo a Metepec S/N  Pablo San Sebastian Xolalpan, 55840, Teotihuacan, México.',telefono:'5547022524',url:'Lapidaria '},
{img:'',nombre:'Gerardo Francisco Oliva Diaz ',direccion:'C. Reforma 1 Pablo San Francisco Mazapa, 55830, Teotihuacan, México. ',telefono:'5524180153',url:'Lapidaria '},
{img:'',nombre:'German Hernandez Nieto',direccion:'C Ignacio Zaragoza S/N Pblo San Francisco Zacango 55885 Acolman, México',telefono:'5949565032',url:'Textil'},
{img:'',nombre:'Gregorio Sosa Perez ',direccion:'Cjon. San Francisco 5 Pablo San Sebastian Xolalpa, 55840, Teotihucan, México. ',telefono:'5574632596',url:'Lapidaria '},
{img:'',nombre:'Guillermo Garcia Montes ',direccion:'Av. Del Trabajo 6 Int 10 Pablo Santa Maria Coatlan, 55835, Teotihuacan, México.',telefono:'5540215342',url:'Lapidaria '},
{img:'',nombre:'Hector Ruben Rivera Diaz ',direccion:'C. Gustavo Baz Prada 109 Col. Nueva Teotihucan, México. ',telefono:'5626722047',url:'Lapidaria '},
{img:'',nombre:'Ignacio Martin Ramos Montes ',direccion:'Av. Del Panteon 2 Pablo San Francisco Mazapa, 55820, Teotihucan, México. ',telefono:'5588284725',url:'Lapidaria '},
{img:'',nombre:'Irma Ana María García Hernandez ',direccion:'Av Hidalgo 26 Col Centro 55800, Teotihuacan, México ',telefono:'5621494915',url:'Agroindustria '},
{img:'',nombre:'Isabel Disciplina Trejo ',direccion:'Cda. Morelos 5 Pablo, San Franciso Mazapan,55830, Teotihuacan, México.',telefono:'5516523224',url:'Lapidaria '},
{img:'',nombre:'Jade Amanda Cortes Fernandez ',direccion:'C. Insurgentes S/N Barrio Puxtla, 55805, Teotihuacan, México. ',telefono:'5544828543',url:'Alfareria '},
{img:'',nombre:'Javier Perez Diaz ',direccion:'Av. Del Panteon S/N Pablo San Francisco Mazapa, 55830, Teotihuacan, México.',telefono:'5628136775',url:'Lapidaria '},
{img:'',nombre:'Jeronimo Juarez Disciplina ',direccion:'Cda. Constitución S/N Pablo San Sebastian Xolalpa, 55824, Teotihucan, México.',telefono:'5578173102',url:'Lapidaria '},
{img:'',nombre:'Jorge Arturo Bazan Rodriguez',direccion:'Av Centenario 9 Pablo San Francisco Mazapa 55830, Teotihuacan, México.',telefono:'5576835646',url:'Agroindustria '},
{img:'',nombre:'Jorge Valdes Godinez',direccion:'C Emiliano Zapata Mza 5 lt 22, col Cayahual 55821 Teotihuacan, México',telefono:'5574141183',url:'Agroindustria '},
{img:'',nombre:'Jose Adrian Cervantes Aguilar ',direccion:'Cam al Ejido de Santa Maria S/N, Santa Maria Coatlan, 55835, Teotihuacan, México. ',telefono:'5518796584',url:'Lapidaria '},
{img:'',nombre:'Jose Cruz Sarabia Rosales ',direccion:'C Adolfo Lopez Mateos 27 Col. Centro, 55800, Teotihuacan, México.',telefono:'5549512763',url:'Alfareria '},
{img:'',nombre:'Jose Querubin Hernandez ',direccion:'C. Morelos 2 Pablo San Francisco Mazapa, 55820, Teotihuacan, México. ',telefono:'5632656066',url:'Lapidaria '},
{img:'',nombre:'Josefina Arredondo Aguilar',direccion:'Carr, México piramides Km 44 Loc. Palomar Atlatongo, 55820 Teotihuacan México ',telefono:'5552968235',url:'Agroindustria '},
{img:'',nombre:'Juan Aureliano Monroy Oliva ',direccion:'Av. Centenario 9 San Francisco Mazapa, 55830, Teotihuacan, México. ',telefono:'5562929058',url:'Lapidaria '},
{img:'',nombre:'Juan Fernando Sanchez Pablo ',direccion:'Av. Hidalgo 11 Santa Maria Coatlan, 55835, Teotihuacan, México.',telefono:'5511913287',url:'Lapidaria '},
{img:'',nombre:'Juan Martínez Torres ',direccion:'C Hombres 32 San Francisco Mazapa, 55820, Teotihuacan , México.',telefono:'5583659992',url:'Lapidaria '},
{img:'',nombre:'Juan Pedro Saniago Colorado',direccion:'C. Francisco Javier mina 4 Barr Evangelista 55816 Teotihuacan, México',telefono:'5532098082',url:'Agroindustria '},
{img:'',nombre:'Juanita Hernandez Quezada ',direccion:'Car. México Tulancingo 31 Barrio Purificación ,55810, Teotihuacan, México.',telefono:'5510128646',url:'Lapidaria '},
{img:'',nombre:'Laura Leticia Ruiz Lopez',direccion:'C San Nicolas 7 Pblo Santiago Atlaongo 55820 Teotihuacan, México',telefono:'5531909787',url:'Cartoneria '},
{img:'',nombre:'Liliana Remedios Barrios Ramirez ',direccion:'C Morelos 20 Pablo San Sebastian Xolalpan, Teotihuacan, México.',telefono:'5547723722',url:'Alfareria '},
{img:'',nombre:'Lucia Delfina Martínez Oliva ',direccion:'C. Jesus María 10 Pablo Santa María Coatlan, 55835, Teotihuacan, México. ',telefono:'5586499660',url:'Lapidaria '},
{img:'',nombre:'Lucia Isabel Valencia Reyes',direccion:'Av. México 5 Pblo Atlatongo 55834 Teotihuacan, México',telefono:'5534775137',url:'Cartoneria '},
{img:'',nombre:'Lucia Ortega Vargas',direccion:'C Francisco Javier Mina 4 Col San Juan Evangelista 55816 Teotihuacan, México',telefono:'5512874298',url:'Agroindustria '},
{img:'',nombre:'Luciano Briones Segura ',direccion:'C Campo Florido 29 Barr Purificacion 55804 Teotihuacan, México',telefono:'5522767252',url:'Madera'},
{img:'',nombre:'Luis Antonio Huerta Aguilar',direccion:'C Guerrero 12 Barr Evangelista 55816 Teotihuacan, México',telefono:'5535142625',url:'Medicina Tradicional '},
{img:'',nombre:'Ma. Alejandra Hernandez Romero ',direccion:'C Durazno 10 Pablo, San Sebastian Xolalpan, 55840, Teotihuacan, México.',telefono:'5593956045',url:'Lapidaria '},
{img:'',nombre:'Ma. Del Rocio Nieves Sanchez ',direccion:'',telefono:'5540593155',url:'Alfareria '},
{img:'',nombre:'Ma. Lucia Rodriguez Hernandez ',direccion:'C de la Barranca 4 Barrio Purificación 55804, Teotihuacan, México ',telefono:'5588330540',url:'Alfareria '},
{img:'',nombre:'Magdalena Dañhu Resendiz ',direccion:'Av. Hidalgo 11 Pablo Santa María Coatlan, 55835, Teotihuacan, México. ',telefono:'5511913287',url:'Lapidaria '},
{img:'',nombre:'Manuel Alejandro Hurtado Sanchez ',direccion:'Av Hombres  Ilustres 16 Pablo San Francisco Mazapan 55820, Teotihuacan,  México.',telefono:'5569865455',url:'Agroindustria '},
{img:'',nombre:'Margarita Enciso German ',direccion:'Cda. Justo Sierra 10 13 Pablo Santa Maria Coatlan 55835, Teotihuacan, México.',telefono:'5540676033',url:'Madera '},
{img:'',nombre:'Margarita Sanchez Vargas ',direccion:'C Campo Florido 5 Barrio Purificación, 55810, Teotihuacan, México. ',telefono:'5578518892',url:'Alfareria '},
{img:'',nombre:'Maria del Carmen Bazan Rodriguez ',direccion:'C. Centenario 9 Pablo, San Francisco Mazapa, 55830, Teotihuacan, México.',telefono:'5616976543',url:'Lapidaria '},
{img:'',nombre:'Maria del Carmen Briones Rodriguez ',direccion:'C. Campo Florido 29 Barrio Purificación 55810, Teotihuacan, México.',telefono:'5529673149',url:'Madera '},
{img:'',nombre:'Maria Michel Cervantes Jimenez ',direccion:'C. Constitucion 24 Pablo San Sebastian Xolalpa, 55840, Teotihuacan, México. ',telefono:'5535023551',url:'Lapidaria '},
{img:'',nombre:'Maria Monserrat Perez Arredondo',direccion:'C. Carretera México Piramides Km 44/2. Pblo Palomar Atlatongo, 55845 Teotihuacan México',telefono:'5534356314',url:'Agroindustria '},
{img:'',nombre:'Maricela Valdespino Saldivar ',direccion:'C las Torres 36 Pablo Maquixco 55843, Teotihuacan, México. ',telefono:'5522984411',url:'Agroindustria '},
{img:'',nombre:'Marina Tepancatl Cruz',direccion:'C Cerro de Ocuila S/N Col Palomar Atlatongo 55845 Teotihuacan, México',telefono:'5633621617',url:'Cartoneria '},
{img:'',nombre:'Mario Santillan Ruiz ',direccion:'Cda. Melchor Ocampó 5, Barrio Puxtla, 55800, Teotihuacan, Méxixo.',telefono:'5539102801',url:'Lapidaria '},
{img:'',nombre:'Marisela Barrera Lopez ',direccion:'Cda. Justo Sierra 12 Pablo, Santa Maria Coatlan, 55835, Teotihuacan, México.',telefono:'5538986296',url:'Lapidaria '},
{img:'',nombre:'Marisol Juarez Reyes ',direccion:'Cam al Ejido de Santa Maria Coatlan, 55835, Teotihuacan, México.',telefono:'5617155024',url:'Lapidaria '},
{img:'',nombre:'Martha Salgado Martínez ',direccion:'C. Cuarto Caminos 20 Pablo Maquizco, 55801, Teotihuacan, México. ',telefono:'5523064157',url:'Alfareria '},
{img:'',nombre:'Miguel Quevedo Gonzalez ',direccion:'Cda. Morelos 4 Pablo San Francisco Mazapa, 55840, Teotihuacan, México. ',telefono:'5524360680',url:'Lapidaria '},
{img:'',nombre:'Miriam Briones Rodriguez ',direccion:'C. Campo Florido 29 Barrio Purificación 55810, Teotihuacan, México.',telefono:'5522767252',url:'Madera '},
{img:'',nombre:'Natalia Martínez Moreno ',direccion:'C Pino 2 Pablo San Sebastian Xolalpan, 55840, Teotihuacan, México. ',telefono:'5554663688',url:'Alfareria '},
{img:'',nombre:'Noe Anselmo Reyes Macias',direccion:'Av HaciendaCadena 2 Pblo Santa Maria Maquixco 55840 Teotihuacan México ',telefono:'5554685090',url:'Cartoneria '},
{img:'',nombre:'Oscar Manuel Sanchez Sanchez',direccion:'A Calandrias S/N Pblo Palomar Atlatongo 55820 Teotihuacan, México',telefono:'5560616542',url:'Talabarteria'},
{img:'',nombre:'Pascuala Sarabia Rivero ',direccion:'C Campo Florido 16 Barrio Purificación, 55804, Teotihuacan, México. ',telefono:'5627535720',url:'Alfareria '},
{img:'',nombre:'Patricia Espinosa Santillan ',direccion:'C Libertad 49 Pablo San Sebastian Xolalpan, Teotihucan, México.',telefono:'5941016250',url:'Alfareria '},
{img:'',nombre:'Patricia Guerrero Olvera',direccion:'C Morelos S/N San Sebastian Xolapa 55824 Teotihuacan, México',telefono:'5539049135',url:'Cartoneria '},
{img:'',nombre:'Pedro Alva Tenorio ',direccion:'C. Campo Florido 14 Barrio Purificación 55810, Teotihuacan, México.',telefono:'5513605568',url:'Lapidaria '},
{img:'',nombre:'Pedro Martínez Oliva ',direccion:'C Minerva 2 Pablo San Francisco Mazapa, 55830, Teotihuacan, México.',telefono:'5583659992',url:'Lapidaria '},
{img:'',nombre:'Pedro Mayorga Reyes ',direccion:'C Gustavo Baz Mza 1 Lt 11 Col Nueva Teotihuacan 55800, Teotihuacan, México ',telefono:'5531923443',url:'Agroindustria '},
{img:'',nombre:'Pedro Soberanez Galicia ',direccion:'Cda. De los Deportes 1 Pablo San Lorenzo Tlamininolpan, Teotihuacan, México ',telefono:'5573254628',url:'Alfareria '},
{img:'',nombre:'Prima Ferreira Promo ',direccion:'Av. Del Panteon 8 a Pablo San Francisco Mazapa, 55830, Teotihuacan, México.',telefono:'5526867662',url:'Lapidaria '},
{img:'',nombre:'Ramon Alvarez Negrete ',direccion:'Cda. Aztecas 5 B Pablo, San Sebastian Xolalpa, 55824, Teotihuacan, México.',telefono:'5941016187',url:'Lapidaria '},
{img:'',nombre:'Roberto Heracilio Sarabia Rosales ',direccion:'Av. Adolfo Lopez Mateos 26 Pablo,55800, Teotihuacan, México.',telefono:'5531368656',url:'Alfareria '},
{img:'',nombre:'Rodolfo Llamas Cuz',direccion:'C Nicolas Bravo Mz a Lt 43 Col Granjas Arenal 56300 Atenco, México',telefono:'5614608253',url:'Medicina Tradicional '},
{img:'',nombre:'Rosa Arvizu Ulloa ',direccion:'C. Gustavo Baz Padra 109 Col. Nueva Teotihuacan 55806, Teotihuacan, México. ',telefono:'5576140865',url:'Lapidaria '},
{img:'',nombre:'Roxana Erendira Alva Nieto ',direccion:'Pridada Morelos 4 Pablo, San Francisco Mazpan, 55820, Teotihuacan, Mexico.',telefono:'5538538049',url:'Lapidaria '},
{img:'',nombre:'Samuel Jimmy Barrios Guerrero',direccion:'C Morelos S/N San Sebastian Xollalpan, 55824, Teotihuacan, México. ',telefono:'5625289676',url:'Alfareria '},
{img:'',nombre:'Sandra Sarabia Rodriguez ',direccion:'C de la Barranca 4 Barrio Purificación 55804, Teotihuacan, México ',telefono:'5591195484',url:'Alfareria '},
{img:'',nombre:'Santiago Angel ojorges Sandoval',direccion:'c de la Presa 5 Barr Purificación 55812 Teotihiuacan, México',telefono:'9981024732',url:'Agroindustria '},
{img:'',nombre:'Santiago Galicia Hernandez ',direccion:'C Libertad 49 Pablo, San Sebastian Xolalpan, 55824, Teotihuacan, México.',telefono:'5540985074',url:'Alfareria '},
{img:'',nombre:'Santiago Vidal Reyes ',direccion:'C Campo Florido 12 Barrio Purificación, 55804, Teotihuacan, México.',telefono:'5565090582',url:'Alfareria '},
{img:'',nombre:'Saturnino Martínez Ortega ',direccion:'Av. Del Puente S/N Pablo San Francisco Mazapa, 55830, Teotihuacan, México.',telefono:'5572104294 5528921742',url:'Lapidaria '},
{img:'',nombre:'Sergio Briones Rodriguez',direccion:'C Campo Florido 29 Barr Purificacion 55804 Teotihuacan, México',telefono:'5618781796',url:'Madera'},
{img:'',nombre:'Sergio Gerardo Silva Rivera ',direccion:'Av. Del Trabajo 13 Loc Santa Maria Coatlan, 55835, Teotihuacan, México. ',telefono:'5615271661',url:'Lapidaria '},
{img:'',nombre:'Silvia Urban Alarcon',direccion:'Plazuela Jose Urban Merino 3 Pblo San Isidro del Progreso 55823 Teotihuacan, México',telefono:'5529615824',url:'Medicina Tradicional '},
{img:'',nombre:'Teofilo Lucio Montes ',direccion:'Cda. Del Reloj 3 Pablo San Francisco Mazapa, 55820, Teotihuacan, México.',telefono:'5585794866',url:'Lapidaria '},
{img:'',nombre:'Teresa de Jesus Espinoza Ignacio',direccion:'Cda. Justo Sierra S/N Pablo Santa Maria Coatlan, 55835, Teotihuacan, Mexico.',telefono:'5565558065',url:'Lapidaria '},
{img:'',nombre:'Thelma Zuli García Dosamantes ',direccion:'C Cerro de Ocuila S/N Col Palomar Atlatongo 55845, Teotihuacan, México.',telefono:'5628047596',url:'Vidrio '},
{img:'',nombre:'Victor Aron Sarabia Sanchez ',direccion:'C Campo Florido 5 Barrio Purificación, 55810, Teotihuacan, México.',telefono:'5568696265',url:'Alfareria '},
{img:'',nombre:'Victor Manuel Santillan Ruiz ',direccion:'Av. Constitución 68 Pablo San Sebastian Xolalpa, 55824, Teotihuacan, México.',telefono:'5620492570',url:'Lapidaria '},
{img:'',nombre:'Victor Sarabia Rivero ',direccion:'C Campo Florido 5 Barrio Purificación, 55810, Teotihuacan, México.',telefono:'5534102641',url:'Alfareria '},
{img:'',nombre:'Vistrain Andrade Gabriel ',direccion:'C Hidalgo 31, San Juan Teotihuacan Izaragoza ',telefono:'5537009435',url:'Alfareria '},
{img:'',nombre:'Yanet Martinez Martinez',direccion:'Cam Viejo a Metepec S/N Pblo San Sebasian Xolalpa 55824 Teotihuacan, México',telefono:'5564365400',url:'Agroindustria '},


        ]
      }
    }
    
  }
</script>